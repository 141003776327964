













import { Component, Vue, Prop } from "vue-property-decorator";
import ProjectBuyerStatisticsReport from "@/components/project-buyer-statistics-report/ProjectBuyerStatisticsReport.vue";
import TokenService from "@/services/TokenService";
import ProjectsService from "@/services/ProjectsService";
import Project from "@/entity/Project";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import ProjectBuyer, { BuyerStatus } from "@/entity/ProjectBuyer";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { BUYER_TYPE_DB_NAMES } from "@/constants";

export type BuyerStatisticsReportStatisticsObject = {
  Interesse: number;
  "Exposé versendet": number;
  Interessensbekundung: number;
  Erstgespräche: number;
  Angebot: number;
  Absage: number;
};

@Component({
  components: {
    ProjectBuyerStatisticsReport,
  },
})
export default class ProjectBuyerStatisticsReportView extends Vue {
  @Prop({ type: String, required: true }) token!: string;

  findings = "";
  nextSteps = "";

  project: Project | null = null;
  buyers: ProjectBuyer[] = [];
  buyerTypes: ProjectValuationBuyerChart[] = [
    {
      name: BUYER_TYPE_DB_NAMES.privateBuyer,
      count: 0,
    },
    {
      name: BUYER_TYPE_DB_NAMES.financialInvestor,
      count: 0,
    },
    {
      name: BUYER_TYPE_DB_NAMES.strategicBuyer,
      count: 0,
    },
  ];
  statistics: BuyerStatisticsReportStatisticsObject | null = null;
  dismissReasons: Record<string, number> = {};

  async created() {
    const token = await TokenService.isValid(this.token);
    if (!token.expired) {
      this.findings = token.meta.findings;
      this.nextSteps = token.meta.nextSteps;
      this.project = await ProjectsService.findOneWithToken(this.token);
      const buyersData = await ProjectBuyersService.findForBuyerStatsReport(
        this.project.id as number,
        this.token,
        { page: 1, itemsPerPage: 9999999 }
      );
      this.buyers = buyersData.content;
      const buyerTypesObject: Record<string, number> = {};
      this.buyers.forEach((buyer) => {
        if (buyer.contact?.buyerType?.name) {
          if (!buyerTypesObject[buyer.contact.buyerType.name])
            buyerTypesObject[buyer.contact.buyerType.name] = 0;
          buyerTypesObject[buyer.contact.buyerType.name]++;
        }
      });
      Object.keys(buyerTypesObject).forEach((item) => {
        const index = this.buyerTypes.findIndex((type) => item === type.name);
        if (index !== -1) {
          this.buyerTypes[index].count = buyerTypesObject[item];
        }
      });
      this.statistics = {
        // API Returns buyers already filtered by is_interested flag
        Interesse: this.buyers.length,
        "Exposé versendet": this.buyers.filter((item) => item.sentExpose)
          .length,
        Interessensbekundung: this.buyers.filter(
          (item) => item.filledExpressionOfInterest
        ).length,
        Erstgespräche: this.buyers.filter((item) => item.meetingArranged)
          .length,
        Angebot: this.buyers.filter((item) => item.purchaseOffer).length,
        Absage: this.buyers.filter((item) => item.dismissedStatusId !== null)
          .length,
      };
      this.buyers.forEach((item) => {
        if (item.dismissedStatus) {
          if (!this.dismissReasons[item.dismissedStatus])
            this.dismissReasons[item.dismissedStatus] = 0;
          this.dismissReasons[item.dismissedStatus]++;
        } else if (item.status === BuyerStatus.dismissed) {
          if (!this.dismissReasons["Keine Begründung"])
            this.dismissReasons["Keine Begründung"] = 0;
          this.dismissReasons["Keine Begründung"]++;
        }
      });
    }
  }
}
