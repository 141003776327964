import Contact, { Nda } from "@/entity/Contact";
import ExpressionOfInterest from "@/entity/ExpressionOfInterest";
import Company from "./Company";

export enum BuyerStatus {
  bought = "prospectstatus.bought",
  dismissed = "prospectstatus.dismissed",
  exposeSent = "prospectstatus.exposesent",
  exposeFollowupSent = "prospectstatus.exposeFollowupsent",
  ndaSent = "prospectstatus.ndasent",
  contractSent = "prospectstatus.contractssent",
  selected = "prospectstatus.selected",
  teaserSent = "prospectstatus.teasersent",
  interested = "prospectstatus.interested",
  notInterested = "prospectstatus.notinterested",
  expressionOfInterestSent = "prospectstatus.expressionofinterestsent",
  expressionOfInterestFilled = "prospectstatus.expressionofinterestfilled",
  offerReceived = "prospectstatus.offerreceived",
  meetingArranged = "prospectstatus.meetingArranged",
}

export const BuyerStatusesWithCounters = [
  BuyerStatus.exposeSent,
  BuyerStatus.expressionOfInterestSent,
  BuyerStatus.expressionOfInterestFilled,
  BuyerStatus.offerReceived,
  BuyerStatus.interested,
  BuyerStatus.notInterested,
  BuyerStatus.meetingArranged,
];

export interface UpdateBuyer {
  interested: boolean | null;
  isPriority: boolean;
  status: BuyerStatus;
  dismissedStatusId: number | null;
  dismissedStatusComment: string | null;
  salesPrice: number | null;
  fixedPrice: number | null;
  earnOutMax: number | null;
  ownerLoan: number | null;
  purchaseOffer: boolean;
}

export interface PurchaseOfferData {
  salesPrice: number | null;
  fixedPrice: number | null;
  earnOutMax: number | null;
  ownerLoan: number | null;
}

export default interface ProjectBuyer {
  id?: number;
  contactId: number;
  projectId: number;
  status: BuyerStatus;
  statusUpdatedAt: number;
  isPriority: boolean;
  dismissedStatusId: number | null;
  dismissedStatus: string;
  dismissedStatusComment: string;
  sentExpose: boolean | null;
  sentExpressionOfInterest: boolean | null;
  filledExpressionOfInterest: boolean | null;
  interested: boolean | null;
  contact: Contact | null;
  expressionOfInterest: ExpressionOfInterest | null;
  purchaseOffer: boolean;
  meetingArranged: boolean | null;
  salesPrice: number | null;
  fixedPrice: number | null;
  earnOutMax: number | null;
  ownerLoan: number | null;
  nda: Nda | null;
  company: Company | null;
}

export const projectBuyerToUpdate = (buyer: ProjectBuyer): UpdateBuyer => ({
  interested: buyer.interested,
  isPriority: buyer.isPriority,
  status: buyer.status,
  dismissedStatusId: buyer.dismissedStatusId,
  salesPrice: buyer.salesPrice,
  fixedPrice: buyer.fixedPrice,
  earnOutMax: buyer.earnOutMax,
  ownerLoan: buyer.ownerLoan,
  dismissedStatusComment: buyer.dismissedStatusComment,
  purchaseOffer: buyer.purchaseOffer,
});

export interface BuyerStatStatus {
  status: string;
  count: number;
}

export interface BuyerStatResponse {
  dismissedStatusId: number;
  dismissedStatus: string;
  count: number;
}

export const canSendEoi = (buyer: ProjectBuyer): boolean => {
  return (
    buyer.contact !== null && !!buyer.sentExpose && buyer.contact.ndaApproved
  );
};

export const hasFilledExpressionOfInterest = (buyer: ProjectBuyer): boolean => {
  return buyer.filledExpressionOfInterest !== false;
};
